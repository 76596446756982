@import url('http://fonts.cdnfonts.com/css/gosha-sans');
@import url('http://fonts.cdnfonts.com/css/basis-grotesque-pro');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  /* border: 2px red dashed; */
  padding: 0;
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* css additional */
body {
    background-color: white;
    /* margin: 0 15%; */
    font-family: 'Inter', 'Cerebri Sans Pro', 'Basis Grotesque Pro', 'Jost', 'Mabry Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.projects {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview_container {
  column-count: 2;
  column-gap: 60px;
  display: block;
}

@media screen and (max-width: 768px) {
  .preview_container {
    column-count: 1;
    grid-column-gap: 0;
  }
}

.section_header {
    font-size: 25px;
    color: #22A6FF;
    margin-bottom: 10px;
}

.section_subheader {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 40px;
  line-height: normal;
}

.section_subheader_email {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: normal;
}

.full_screen_width {
  width: 100vw;
  height: auto;
}

.ItemNum {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
}

.max_width_sixty {
  max-width: 60%;
}

a {
  text-decoration: none;
  color: #000000;
}

.logo_navbar {
  max-height: 50px;
  width: auto;
}

.span_picture {
  max-height: 40px;
  margin-top: -5px;
  display: inline-block;
  vertical-align: middle;
}

.span_picture2 {
  max-height: 50px;
  display: inline-block;
  align-self: center;
  vertical-align: middle;
}

.span_picture_small {
  max-height: 25px;
  margin-top: -5px;
  display: inline-block;
  vertical-align: middle;
}

.opacity_emoji {
  opacity: 70%;
}

.opacity_emoji:hover {
  opacity: 100%;
}

.opacity_50 {
  opacity: 50%;
}

.border_radius_50 {
  border-radius: 50%;
}

.bold_text {
  font-weight: 700;
}

.orange_text {
  color: #FFA122;
}

.blue_text {
  color: #296DC0;
}

.green_text {
  color: #1ED760;
}

.underlined_text {
  text-decoration: underline;
}

.underline_on_hover:hover {
  text-decoration: underline;
}

.regular_weight {
  font-weight: 400;
}

.bold_weight {
  font-weight: 700;
}

.flame_border {
  border: 8px #FDEDED solid;
}

.orange_border {
  border: 6px #FFA122 solid;
}


.small_screen {
  display: none;
}

@media screen and (max-width: 480px) {
  .large_screen {
    display: none;
  }
  
  .small_screen {
    display: flex;
  }
}

::selection {
  background: #a3daff; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #a3daff; /* Gecko Browsers */
}

@media screen and (max-width: 600px) {
  .side_image_width {
    width: 90%;
    margin: 0 auto;
  }
}

.linear_wipe {
  /* text-align: center; */
  font-weight: 600;
  background: linear-gradient(to right, #fff 20%, #22A6FF 40%, #22A6FF 60%, #fff 80%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
 }
}

.fadein_on_land {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s ease-in-out;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}